import React from "react";

const Backend = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title">Anderes</h3>

      <div className="skills__box">
        <div className="skills__group">

        <div className="skills__data">
            <i class="bx bx-badge-check"></i>

            <div>
              <h3 className="skills__name">Agiles Management</h3>
           
            </div>
          </div>
          <div className="skills__data">
            <i class="bx bx-badge-check"></i>

            <div>
              <h3 className="skills__name">Consulting</h3>
              
            </div>
          </div>

       

        
        </div>

        <div className="skills__group">
          <div className="skills__data">
            <i class="bx bx-badge-check"></i>

            <div>
              <h3 className="skills__name">Requirements Engineering</h3>
            </div>
          </div>

          <div className="skills__data">
            <i class="bx bx-badge-check"></i>

            <div>
              <h3 className="skills__name">Projektarbeit</h3>
              
            </div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default Backend;
