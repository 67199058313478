import Work1 from "../../assets/work1.jpg";
import Work2 from "../../assets/work2.jpg";
import Work3 from "../../assets/work3.jpg";
import Work4 from "../../assets/work4.jpg";
import Work5 from "../../assets/work5.jpg";
import Work6 from "../../assets/work6.jpg";
import Work7 from "../../assets/work7.jpg";
import Work8 from "../../assets/work8.jpg";
import Work9 from "../../assets/work9.jpg";
import Work10 from "../../assets/work10.jpg";
import Work11 from "../../assets/work11.jpg";
import Work12 from "../../assets/work12.jpg";
import Work14 from "../../assets/work14.jpg";
import Work15 from "../../assets/work15.jpg";
import Work16 from "../../assets/work16.jpg";
import Work17 from "../../assets/work17.jpg";
import Work18 from "../../assets/work18.jpg";





export const projectsData = [
  {
    id: 1,
    image: Work1,
    title: "Handwai",
    category: "web",
    link: "https://www.handwai.com",
    altText: "",
  },
  {
    id: 2,
    image: Work6,
    title: "Roboter Pepper",
    category: "nlp",
    link: "",
    altText: "Integration von Sprachsteuerung",
  },

  {
    id: 16,
    image: Work2,
    title: "LAM Studio",
    category: "web",
    link: "https://www.lam-studio.de"
  },
  {
    id: 10,
    image: Work3,
    title: "Mitarbeiter-Check-Up",
    category: "web",
    link: "https://mitarbeiter-check-up.de/"
  },
  {
    id: 14,
    image: Work10,
    title: "LAR Systems",
    category: "NLP",
    link: "",
    altText: "Sprachsteuerung auf Baustellen",
  },
  {
    id: 20,
    image: Work4,
    title: "Elektro Woratsch",
    category: "web",
    link: "https://www.google.de"
  },
  {
    id: 19,
    image: Work7,
    title: "Mobile App Total Fansports",
    category: "App",
    link: "",
    altText: "Mitarbeit am Projekt",
  },

  {
    id: 17,
    image: Work8,
    title: "Scraper Hotelcareer",
    category: "App",
    link: "",
    altText: "Scraping für Headhunter aus der Gastro",
   
  },
  {
    id: 3,
    image: Work9,
    title: "Voice Assistant Universität",
    category: "nlp",
    link: "https://www.studierendenwerk-pb.de/wir-ueber-uns/weiteres/alexa-skill/"
  },
  {
    id: 4,
    image: Work5,
    title: "Smart Voice Control",
    category: "web",
    link: "",
    altText: "inaktiv",
  },




  {
    id: 7,
    image: Work12,
    title: "Franchise Flow",
    category: "web",
    link: "",
    altText: "inaktiv",
  },
  {
    id: 8,
    image: Work15,
    title: "CosDaMed",
    category: "web",
    link: "https://www.cosdamed.de/"
  },
  {
    id: 18,
    image: Work8,
    title: "Scraper für Zeitungsartikel",
    category: "App",
    link: "",
    altText: "+1.2m Artikel über 4 Scraper",
  },
  {
    id: 9,
    image: Work18,
    title: "LV-Analyse",
    category: "web",
    link: "https://leistungsverzeichnis-analyse.de/"
  },

  {
    id: 11,
    image: Work11,
    title: "Schöne Aussicht Trier",
    category: "web",
    link: "",
    altText: "inaktiv",
  },
  {
    id: 12,
    image: Work17,
    title: "Hausbesuch Vereinbaren",
    category: "web",
    link: "https://www.hausbesuch-vereinbaren.de"
  },
  {
    id: 13,
    image: Work16,
    title: "German Doctors",
    category: "web",
    link: "https://www.german-doctors.com"
  },
  {
    id: 5,
    image: Work14,
    title: "Express Taxi Trier",
    category: "web",
    link: "",
    altText: "inaktiv",
  },



];

// projects
export const projectsNav = [
  {
    name: "alle",
  },
  {
    name: "web",
  },
  {
    name: "app",
  },
  {
    name: "nlp",
  },
];
