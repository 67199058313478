import React from "react";

const Social = () => {
  return (
    <div className="home__social">
               <a
            href="https://github.com/Awo26"
            className="footer__social-link"
            target="_blank"
          >
            <i class='bx bxl-github'></i>
          </a>

          <a
            href="https://www.linkedin.com/in/aleksej-woratsch-8b8133275/"
            className="footer__social-link"
            target="_blank"
          >
            <i class='bx bxl-linkedin-square'></i>
          </a>

    </div>
  );
};

export default Social;
