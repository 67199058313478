import React from 'react';
import "./App.css";
import Header from './components/header/Header';
import Home from './components/home/Home';
import About from './components/about/About';
import Skills from './components/skills/Skills';
import Services from './components/services/Services';
import Qualification from './components/qualification/Qualification';
import Work from './components/Portfolio/Work';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import ScrollUp from './components/scrollup/ScrollUp';
import CookieConsent from 'react-cookie-consent';

const App = () => {
  return (
    <>
    <Header />
    <CookieConsent debug={true}
    location='bottom'
    style={{background: '#000', textAlign:'left'}}
    buttonStyle={{color: '#000', background: '#fff'}}
    buttonText="Verstanden"
    expires={365}
    >

      Diese Seite verwendet Cookies. Weitere <a href='datenschutz.html' target='_blank'>Informationen</a>.
    </CookieConsent>

    <main className='main'>
      <Home />
      <About />
      <Skills />
      <Services />
      <Qualification />
      <Work />
     
      <Contact />
    </main>

    <Footer />
    <ScrollUp />
    </>
  )
}

export default App